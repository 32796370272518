import _ from "underscore";
import moment from 'moment';
//获取列表详情
import flight_domestic_buyer_manage_order_refList from '@/lib/data-service/flight/flight_domestic_buyer_manage_order_refList';
import flight_domestic_buyer_manage_order_refStates from '@/lib/data-service/flight/flight_domestic_buyer_manage_order_refStates';
import flight_domestic_airlines from '@/lib/data-service/flight/flight_domestic_airlines';
import order_cancel from '@/lib/data-service/flight/flight_domestic_buyer_white_order_cancel';
import scrollTop from '@/lib/common-service/scrollTop';
import refundRecordCount from '@/lib/data-service/flight/flight_domestic_buyer_manage_order_refundRecordCount';
import confirmRefund from '@/lib/data-service/flight/newFligthApi/buyer_dom_manage_confirmRefund';
import cancelRefund from '@/lib/data-service/flight/newFligthApi/buyer_dom_manage_cancelRefund';
import AirlineSelect from '@/page/distributor/air-ticket/components/airlineSelect.vue';
import plat_dom_getAllProductType from '@/lib/data-service/flight/plat_dom_getAllProductType';
import { awaitWrap } from "@/page/distributor/train/front/common/unit";
import RefundChangeLabel from '@/page/distributor/air-ticket/admin/component/refund-change-label/index.vue';
import plat_dom_getPartProductType from '@/lib/data-service/flight/plat_dom_getPartProductType';
export default {
  components: { AirlineSelect, RefundChangeLabel },
  data() {
    return {
      //timeUpbox: null,
      //timeUpboxHeight: null,
      searchForm: {
        pnr: '',
        orderNo: '',
        refundOrderNo:'',
        ticketNumber: '',

        airCode: '',
        psgName: '',
        productType: '',

        __orderTimeStart: '',
        __orderTimeEnd: '',

        refundStatus: 1,
        refReason: '',
      },
      cancelOrderNo: '',
      dialogVisible: false,
      pageResult: {
        currentPage: 1,
        pageSize: 10,
        pageData: [],
        totalCount: 0,
      },
      pCurrentPage: 1,
      data_flight_domestic_airlines: {
        pageResult: {
          pageData: [],
        }
      },

      data_flight_domestic_buyer_manage_order_refStates: {
        pageResult: {
          pageData: [],
        }
      },
      refundRecordCountList: [],
      loading: true,
      NavItemActive: 0,
      dialogInfo: {
        text: "",
        type: 1
      },
      productTypeList: [],
      selectProductTypeList: [
        /*{ label: '全部', value: '' },
        { label: '平台优选', value: '1' },
        { label: '官网', value: '2' },
        { label: '低价推荐', value: '3' }*/
      ],
      refReasonList: [{code: '', msg: '全部'}, {code: 1, msg: '自愿退票'}, {code: 2, msg: '非自愿退票'}], // 退票类型 (1.自愿退票 2.非自愿退票)
    };
  },
  computed: {
    orderTimeStart: {
      get() {
        const __this = this;
        if (__this.searchForm.__orderTimeStart !== '' && __this.searchForm.__orderTimeStart !== null && __this.searchForm.__orderTimeStart.length > 0) {
          return __this.searchForm.__orderTimeStart[0] + ' 00:00:00'
        } else {
          return ''
        }
      }
    },
    orderTimeEnd: {
      get() {
        const __this = this;
        if (__this.searchForm.__orderTimeStart !== '' && __this.searchForm.__orderTimeStart !== null && __this.searchForm.__orderTimeStart.length > 0) {
          return __this.searchForm.__orderTimeStart[1] + ' 23:59:59'
        } else {
          return ''
        }
      }
    },
  },
  methods: {
    getPartProductType() {
      plat_dom_getPartProductType().then(res => {
        let selectProductTypeList = res.producyTypes
        selectProductTypeList.unshift({
          code: '',
          msg: '全部'
        })
        this.selectProductTypeList = selectProductTypeList
      })
    },
    confirm() {
      let orderNo = this.dialogInfo.orderNo
      let type = this.dialogInfo.type
      if (type == 2) {
        confirmRefund({ refOrderNo: orderNo }).then(res => {
          console.log(res)
          if (res.status) {
            this.dialogVisible = false
            this.__data_bind_list()
            this.$message({ type: "success", message: "确认退票成功！" })
          }
        })
      } else {
        cancelRefund({ refOrderNo: orderNo }).then(res => {
          this.dialogVisible = false
          this.__data_bind_list()
          this.$message({ type: "success", message: "取消退票成功！" })
        })
      }
    },
    async __data_bind_list(pParameter) {
      const __this = this;
      if (!pParameter) pParameter = {};
      const currentPage = pParameter.currentPage || __this.pageResult.currentPage;
      const pageSize = pParameter.pageSize || __this.pageResult.pageSize;

      const pnr = __this.searchForm.pnr;
      const orderNo = __this.searchForm.orderNo;
      const refundOrderNo = __this.searchForm.refundOrderNo;
      const ticketNumber = __this.searchForm.ticketNumber;
      const airCode = __this.searchForm.airCode;
      const psgName = __this.searchForm.psgName;
      const refundStatus = __this.searchForm.refundStatus;
      let _fromData = this.searchForm;
      const orderTimeStart = __this.orderTimeStart;
      const orderTimeEnd = __this.orderTimeEnd;
      const productType = __this.searchForm.productType;
      const refReason = __this.searchForm.refReason;


      let data = {
        currentPage: currentPage,
        pageSize: pageSize,
        pnr: pnr,
        orderNo: orderNo,
        ticketNumber: ticketNumber,
        airCode: airCode,
        psgName: psgName,
        refundStatus: refundStatus,
        appTimeBegin: orderTimeStart,
        appTimeEnd: orderTimeEnd,
        flightNo: _fromData.flightNo,
        contactTel: _fromData.contactTel,
        psgCardNo: _fromData.psgCardNo,
        refundOrderNo: refundOrderNo,
        productType,
        refReason
      };
      __this.loading = true;
      flight_domestic_buyer_manage_order_refList(data).then(res => {
        __this.pageResult.pageData = res.pageResult.pageData || [];
        __this.pageResult.totalCount = res.pageResult.totalCount;
        __this.loading = false;
        this._refundRecordCount(data)
        scrollTop(500)
      }).catch(error => {
        __this.pageResult.pageData = []
        __this.pageResult.totalCount = 0
        __this.loading = false;
      })
    },

    __el_pagination_current_change_event_handler(pCurrentPage) {
      const __this = this;
      __this.pageResult.currentPage = pCurrentPage
      __this.__data_bind_list();
    },

    setOrdersNo(ordersNo, toString, psgList) {
      let psgType
      if (psgList) {
        let psg = psgList.map(item => {
          return item.psgType
        })
        if (psg.indexOf(1) != -1) {
          psgType = 1
        } else {
          psgType = 2
        }
      }
      // if(type=='pay'){
      //   return this.$router.push({name:toString,query:{ordersNo:ordersNo,pay:'12'}})
      // }
      this.$router.push({
        name: toString,
        query: {
          ordersNo,
          psgType,
          orderType: 'refund'
        }
      })
    },

    cancel(ordersNo) {
      let parame = {
        orderNo: ordersNo,
        remark: ''
      }
      order_cancel(parame).then(res => {
        res.isSuccess ?
          this.$message({ type: "warning", message: "操作成功！" }) : ''
        this.__data_bind_list({ currentPage: this.pCurrentPage })
      })
    },
    _refundRecordCount(val) {
      refundRecordCount(val).then(res => {
        this.refundRecordCountList = res.records
      })
    },
    __el_pagination_size_change_event_handler(pPageSize) {
      const __this = this;
      __this.pageResult.pageSize = pPageSize
      __this.__data_bind_list();
    },

    __btn_search_click(val) {
      const __this = this;
      __this.__data_bind_list({ currentPage: 1 });
    },

    __btn_reset_click() {
      const __this = this;

      _.each(__this.searchForm, function (property_value, property_name) {
        __this.searchForm[property_name] = ``;
      });
      __this.searchForm.refundStatus = `1`;

      __this.__data_bind_list();
    },

    __btn_tab_click(pItem, pIndex) {
      const __this = this;
      __this.searchForm.refundStatus = pItem.status;
      __this.NavItemActive = pIndex;
      this.pageResult.currentPage = 1
      __this.__btn_search_click();
    },
    // offHeight(e) {
    //   this.timeUpboxHeight = e.target.offsetParent.offsetHeight
    //   // this.timeUpboxHeight = e.fromElement.localName == 'td' ? e.fromElement.offsetHeight : 0
    // },
    async getAllProductType() {
      let [err, res] = await awaitWrap(plat_dom_getAllProductType())
      console.log(res)
      if (err) {
        return
      }
      let productTypeList = res.producyTypes
      productTypeList.unshift({
        code: '',
        msg: '全部'
      })
      this.productTypeList = productTypeList
    },
    setTagType(val) {
      let type = ''
      const typeList = ['policy-label-1', 'policy-label-2', 'policy-label-3', 'policy-label-4', 'policy-label-5', 'policy-label-6']
      this.productTypeList.forEach((value, index) => {
        const codeList = value.code.split(',')
        if (codeList.indexOf(val) >= 0) {
          type = typeList[index]
        }
      })
      return type
    },
    reactRefund(val) {
      this.$router.push({
        name: 'air-ticket-admin-react-refund',
        query: {
          orderNo: val.orderNo,
          refOrderNo: val.refundOrderNo,
          psgType: 1,
        }
      })
    },
  },
  watch: {
    '$route.query.ordersNo': function (newId) {
      this.__data_bind_list()
    }
  },
  async mounted() {
    const __this = this;

    const data_flight_domestic_airlines = await flight_domestic_airlines();
    __this.data_flight_domestic_airlines = data_flight_domestic_airlines;
    this.getPartProductType()
    await this.getAllProductType();
    // const data_flight_domestic_buyer_manage_order_refStates = await flight_domestic_buyer_manage_order_refStates();
    // __this.data_flight_domestic_buyer_manage_order_refStates = data_flight_domestic_buyer_manage_order_refStates;

    __this.__data_bind_list();
  },
}
