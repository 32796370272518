const __request = require(`../__request/flight-admin/__request_contentType_json`);

// 国内退票确认退票
export default (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/buyer/dom/manage/confirmRefund',
        data: data
    }

    return __request(pParameter)
}

